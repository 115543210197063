// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-contact-template-js": () => import("/opt/build/repo/src/templates/ContactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-index-template-js": () => import("/opt/build/repo/src/templates/IndexTemplate.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-construction-template-js": () => import("/opt/build/repo/src/templates/ConstructionTemplate.js" /* webpackChunkName: "component---src-templates-construction-template-js" */),
  "component---src-templates-rent-template-js": () => import("/opt/build/repo/src/templates/RentTemplate.js" /* webpackChunkName: "component---src-templates-rent-template-js" */),
  "component---src-templates-rent-detail-template-js": () => import("/opt/build/repo/src/templates/RentDetailTemplate.js" /* webpackChunkName: "component---src-templates-rent-detail-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

